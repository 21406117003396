<template>
  <div :class="$style.introduce">
    <div :class="$style.content">
      <p :class="$style.title">平台介绍</p>
      <BjEditor ref="editor" v-model="params.content" placeholder="请在这里描述平台的详细信息。" />
    </div>

    <div :class="$style.footer">
      <BjButton class="mr-10" type="primary" @click="onSave()">
        <i class="ri-send-plane-2-line left" />
        立即保存
      </BjButton>
      <BjButton @click="onCancel()"> 取消保存 </BjButton>
    </div>
  </div>
</template>

<script>
import BjEditor from '@/components/BjEditor'
import { platformService } from '@/service'

const service = new platformService()

export default {
  name: 'introduce',
  components: {
    BjEditor,
  },
  data() {
    return {
      params: {
        content: null,
      },
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getAgreement({
          key: 'describe',
        })
        this.params.content = data.content
      } catch (e) {}
    },
    async onSave() {
      try {
        await service.saveAgreement({
          key: 'describe',
          content: this.params.content,
        })
        this.$message.success('保存成功')
        this.$router.push({
          name: 'systemPlatform',
        })
      } catch (e) {}
    },
    onCancel() {
      this.$router.push({
        name: 'systemPlatform',
      })
    },
  },
}
</script>

<style lang="less" module>
.introduce {
  padding: 20px;

  .content {
    height: calc(100vh - 212px);
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;

    .title {
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 60px;
    padding-left: 20px;
    line-height: 60px;
    background-color: #fff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
  }
}
</style>
